import axios from "../axios-api";
import { throwCatchedError, updateSourceToken } from "utils";

let getCampaignStatsSourceToken;
let getRecipientsStatsSourceToken;

export const getCampaignStats = (token, campaignId, sourceToken = null) => {
  getCampaignStatsSourceToken = updateSourceToken(
    getCampaignStatsSourceToken,
    sourceToken
  );
  const requestUrl = `/mailing/stats`;

  return axios
    .get(requestUrl, {
      cancelToken: getCampaignStatsSourceToken.token,
      params: {
        access_token: token,
        campaignId,
      },
    })
    .catch(function (thrown) {
      throwCatchedError(thrown);
    });
};

export const getRecipientsStats = (
  token,
  page = 1,
  pageSize = undefined,
  campaignId,
  name = null,
  status = null,
  linkId = null,
  sourceToken = null,
  nolimit = false
) => {
  getRecipientsStatsSourceToken = updateSourceToken(
    getRecipientsStatsSourceToken,
    sourceToken
  );
  const fields = [
    "id",
    "email",
    "lastName",
    "firstName",
    "bounceType",
    "deliverAt",
    "openCount",
    "bounceAt",
    "openAt",
    "userGroup",
    "clickAt",
    "sentStatus",
    "recipientType",
    "recipientId",
  ];
  const requestUrl = `/mailing/campaign-recipient/list`;

  let filter = [
    {
      property: "campaign.id",
      value: campaignId,
      operator: "eq",
    },
  ];
  if (name) {
    filter.push({
      property: "name",
      value: name,
      operator: "like",
    });
  }

  if (linkId || status == "LINK_CLICKS") {
    filter.push({
      property: "status",
      value: "LINK_CLICKS",
      operator: "eq",
      linkId,
      campaignId,
    });
  } else if (status) {
    filter.push({
      property: "status",
      value: status,
      operator: "eq",
    });
  }

  let params = {
    access_token: token,
    fields: fields.join(","),
    filter: JSON.stringify(filter),
    limit: pageSize,
    start: (page - 1) * pageSize,
  };

  if (nolimit) {
    params.nolimit = 1;
  }

  return axios
    .get(requestUrl, {
      cancelToken: getRecipientsStatsSourceToken.token,
      params,
    })
    .catch(function (thrown) {
      throwCatchedError(thrown);
    });
};

export const reSendEmail = (token, id, email, updateEmail) => {
  const requestUrl = `/mailing/email/resend`;
  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("id", id);
  if (email) {
    formData.append("email", email);
  }
  if (updateEmail) {
    formData.append("updateEmail", updateEmail);
  }

  return axios.post(requestUrl, formData);
};

export const getSMSCampaignStats = (
  token,
  campaignSMSId,
  sourceToken = null
) => {
  getCampaignStatsSourceToken = updateSourceToken(
    getCampaignStatsSourceToken,
    sourceToken
  );
  const requestUrl = `/sms/sms-stats`;

  return axios
    .get(requestUrl, {
      cancelToken: getCampaignStatsSourceToken.token,
      params: {
        access_token: token,
        campaignSMSId,
      },
    })
    .catch(function (thrown) {
      throwCatchedError(thrown);
    });
};

export const getSMSRecipientsStats = (
  token,
  page = 1,
  pageSize = 10,
  campaignSMSId,
  name = null,
  status = null,
  linkId = null,
  sourceToken = null
) => {
  getRecipientsStatsSourceToken = updateSourceToken(
    getRecipientsStatsSourceToken,
    sourceToken
  );
  const fields = [
    "id",
    "phoneNumber",
    "countryCode",
    "lastName",
    "firstName",
    "smsCount",
    "smsPrice",
    "sentStatus",
    "recipientType",
    "recipientId",
  ];
  const requestUrl = `/sms/sms-campaign-recipient`;

  let filter = [
    {
      property: "smsCampaign",
      value: campaignSMSId,
      operator: "eq",
    },
  ];
  if (name) {
    filter.push({
      property: "name",
      value: name,
      operator: "like",
    });
  }

  if (status) {
    filter.push({
      property: "status",
      value: status,
      operator: "eq",
    });
  }
  if (linkId) {
    filter.push({
      property: "linkId",
      value: linkId,
      operator: "eq",
    });
  }

  return axios
    .get(requestUrl, {
      cancelToken: getRecipientsStatsSourceToken.token,
      params: {
        access_token: token,
        fields: fields.join(","),
        filter: JSON.stringify(filter),
        limit: pageSize,
        start: (page - 1) * pageSize,
      },
    })
    .catch(function (thrown) {
      throwCatchedError(thrown);
    });
};

export const editCampaignLink = (token, id, link) => {
  const requestUrl = `/mailing/link/${id}`;
  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("link", link);

  return axios.post(requestUrl, formData);
};
