import React, { Component } from "react";
import { connect } from "react-redux";
import {
  setRecipientsStatsFilterLinkId,
  setRecipientsStatsFilterName,
  setRecipientsStatsFilterStatus,
} from "actions";
import Recipients from "./Recipients";
import _ from "i18n";
import { initRecipientsStatsFilterStatus } from "actions";
import TTPPaginator from "common/list/TTPPaginator";
import SearchBox from "common/filter/SearchBox";
import PageSize from "common/list/PageSize";
import ColoredSelect from "common/ColoredSelect";
import { RECIPIENTS_STATUS } from "Common";
import NotFound from "notFound/NotFound";
import { fetchRecipientsStats } from "thunks";

@connect((store) => {
  return {
    recipients: store.campaigns.stats.recipients.list,
    recipientsFilter: store.campaigns.stats.recipients.filter,
  };
})
export default class RecipientsStats extends Component {
  constructor(props) {
    super(props);
    this.state = { oldSearchQuery: "", showFullLink: false };

    this.handleSelectStatus = this.handleSelectStatus.bind(this);
    this.handleShowMoreClick = this.handleShowMoreClick.bind(this);
  }

  componentWillUnmount() {
    this.props.dispatch(initRecipientsStatsFilterStatus());
  }

  handleSelectStatus(inputValue) {
    const { dispatch } = this.props;
    let status = inputValue.value;
    dispatch(setRecipientsStatsFilterStatus(status));
  }

  handleSearchChange = (word) => {
    const { dispatch, renderRecipientStatsDatatable } = this.props;
    let { oldSearchQuery } = this.state;
    if (word != oldSearchQuery) {
      dispatch(setRecipientsStatsFilterName(word));
      renderRecipientStatsDatatable(1);
    }
    this.setState({ oldSearchQuery: word });
  };

  reloadData() {
    const { renderRecipientStatsDatatable, dispatch } = this.props;
    dispatch(setRecipientsStatsFilterStatus(null));
    dispatch(setRecipientsStatsFilterLinkId(null));
    renderRecipientStatsDatatable(1, 10);
    this.handleRefresh(); // Call handleRefresh to set clickedLink to null
  }

  handleRecipientsStatsListPageClick(data) {
    let selectedPage = data.selected;
    this.props.renderRecipientStatsDatatable(selectedPage + 1);
  }

  handleSelectRecipientsStatsListPageSize(pageSize) {
    this.props.renderRecipientStatsDatatable(1, pageSize);
  }
  downloadCSV = async () => {
    const {
      dispatch,
      exportCompaignStats,
      match: { params: routeParams },
    } = this.props;
    await dispatch(fetchRecipientsStats(routeParams.campaignId, 1));
    exportCompaignStats();
  };
  handleRefresh() {
    this.props.handleRefresh();
  }
  handleShowMoreClick() {
    this.setState((prevState) => ({
      showFullLink: !prevState.showFullLink,
    }));
  }

  render() {
    const {
      recipients,
      recipientsFilter,
      recepientsExist,
      links,
      clickedLink,
      isArchived,
    } = this.props;
    let headerColor = null;

    for (let i = 0; i < RECIPIENTS_STATUS.length; i++) {
      if (RECIPIENTS_STATUS[i].value === recipientsFilter.status) {
        headerColor = RECIPIENTS_STATUS[i].color;
        break;
      }
    }
    let formattedClickedLink = clickedLink;
    if (clickedLink && clickedLink?.length > 120) {
      formattedClickedLink = clickedLink?.slice(0, 120) + "...";
    } else {
      formattedClickedLink = clickedLink;
    }

    return (
      <div id="recipients-details" className="stats-page__block">
        <div className="recipients-link-clicked">
          <h4 className="text-capitalize">{_("recipients")}</h4>
          {formattedClickedLink && (
            <div className="link-clicked">
              {"(" +
                (this.state.showFullLink ? clickedLink : formattedClickedLink)}
              {clickedLink && clickedLink.length > 120 && (
                <span
                  onClick={this.handleShowMoreClick}
                  className={`show-more-link ${
                    this.state.showFullLink ? "show-less" : ""
                  }`}
                >
                  {this.state.showFullLink ? _("See_less") : _("See_more")}
                </span>
              )}
              {")"}
            </div>
          )}
        </div>

        <div className="row align-center">
          <div className="filter-container columns">
            <ColoredSelect
              statuses={RECIPIENTS_STATUS}
              onChange={this.handleSelectStatus}
              value={recipientsFilter.status}
            />
            {recepientsExist && (
              <div onClick={this.downloadCSV} className="download-csv">
                {_("DownloadCSV")}
                <i className="icon-right icon-cloud-download" />
              </div>
            )}
            <div className="search">
              <span
                className="float-right refresh"
                onClick={this.reloadData.bind(this)}
              >
                <i className="icon-refresh"></i>
              </span>
            </div>
            <SearchBox onChange={this.handleSearchChange} />
            <PageSize
              pageSize={recipients.pageSize}
              onChange={this.handleSelectRecipientsStatsListPageSize.bind(this)}
            />
          </div>
        </div>
        {recipients?.items?.length > 0 ? (
          <Recipients headerColor={headerColor} isArchived={isArchived} />
        ) : (
          <NotFound text="empty_recipients_list" />
        )}
        {recipients?.items?.length > 0 && (
          <TTPPaginator
            onPageChange={this.handleRecipientsStatsListPageClick.bind(this)}
            pageSize={recipients.pageSize}
            nbResult={recipients.nbResult}
            paginationPage={recipients.paginationPage}
          />
        )}
      </div>
    );
  }
}
